import UserControllerBase from '../base'
import type { NDeleteInvite, NEditInvite, NSendInvite } from './types.js'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class UserControllerInvite extends UserControllerBase {
  static readonly controllerPath = 'invite'
  private readonly serviceUrl: string

  constructor() {
    super(UserControllerInvite.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async acceptInvite(inviteId: string): TResponseBase<boolean> {
    return await useFetchInstance(`${this.serviceUrl}/${inviteId}/received`, {
      method: 'POST',
    })
  }

  async resendInvite(inviteId: string): TResponseBase<string> {
    return await useFetchInstance(`${this.serviceUrl}/${inviteId}/resend`, {
      method: 'POST',
    })
  }

  async deleteInvite(
    body: NDeleteInvite.TBody,
  ): TResponseBase<NDeleteInvite.TResponseData> {
    return await useFetchInstance(`${this.serviceUrl}`, {
      method: 'DELETE',
      body,
    })
  }

  async sendInvite(
    body: NSendInvite.IBody,
  ): TResponseBase<NSendInvite.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}`, {
      method: 'POST',
      body,
    })
  }

  async editInvite(
    inviteId: string,
    body: NEditInvite.IBody,
  ): TResponseBase<NEditInvite.TResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${inviteId}`, {
      method: 'PATCH',
      body,
    })
  }
}
