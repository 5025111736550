import UserControllerBase from '../base'
import type { NBusinessProfiles } from './types'

export default class BusinessProfilesController extends UserControllerBase {
  static readonly controllerPath = 'BusinessProfiles'
  private readonly serviceUrl: string

  constructor() {
    super(BusinessProfilesController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getBusinessProfiles(): Promise<NBusinessProfiles.IBusinessProfiles[]> {
    return await useFetchInstance(`${this.serviceUrl}`, {
      method: 'GET',
    })
  }
}
