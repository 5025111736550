import UserControllerBase from '../base'
import type { NGetAssignableRolesForInvite } from './types'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class UserControllerAuthorization extends UserControllerBase {
  static readonly controllerPath = 'authorization'
  private readonly serviceUrl: string

  constructor() {
    super(UserControllerAuthorization.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getAssignableRolesForInvite(
    organizationId: string,
    params?: NGetAssignableRolesForInvite.IParams,
  ): TResponseBase<NGetAssignableRolesForInvite.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/assignablerolesforinvite`,
      {
        method: 'GET',
        params,
      },
    )
  }
}
