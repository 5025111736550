import UserControllerBase from '../base'
import type { NSmartInsight } from './types'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class SmartInsightController extends UserControllerBase {
  static readonly controllerPath = 'smartinsight'
  private readonly serviceUrl: string

  constructor() {
    super(SmartInsightController.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async get(
    organizationId: string,
    webAreaId: number,
  ): TResponseBase<NSmartInsight.webArea> {
    return await useFetchInstance(
      `${this.serviceUrl}/${organizationId}/${webAreaId}`,
      {
        method: 'GET',
      },
    )
  }
}
