import UserControllerBase from '../base'
import type { NLogin } from './types'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class UserControllerAuthentication extends UserControllerBase {
  static readonly controllerPath = 'authentication'
  private readonly serviceUrl: string

  constructor() {
    super(UserControllerAuthentication.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async login(body: NLogin.IBody): TResponseBase<NLogin.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/login`, {
      method: 'POST',
      body,
    });
  }
}
