import UserControllerAuthentication from './authentication'
import UserControllerInvite from './invite'
import UserControllerOrganization from './organization'
import UserControllerUser from './user'
import SmartInsightController from './smartinsight'
import BusinessProfilesController from './BusinessProfiles'
import UserControllerAuthorization from './authorization'

export default class UserService {
  public organization = new UserControllerOrganization()
  public authentication = new UserControllerAuthentication()
  public user = new UserControllerUser()
  public invite = new UserControllerInvite()
  public smartInsight = new SmartInsightController()
  public businessProfiles = new BusinessProfilesController()
  public authorization = new UserControllerAuthorization()
}
